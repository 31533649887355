@import '@angular/cdk/overlay-prebuilt.css';
@import "primeicons/primeicons.css";
@import 'primeng/resources/primeng.min.css';

@font-face {
  font-family: HMAmpersand-Regular;
  src: url(assets/HMAmpersand/HMAmpersand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: HMAmpersand-Bold;
  src: url(assets/HMAmpersand/HMAmpersand-Bold.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'HM Group Ampersand';
  font-size: 14px;
}

.p-datatable-table {
  border-spacing: 0px;
  width: 100%;
}
td {
  border-bottom: 1px solid;
  border-block-color: silver;
  padding: 10px;
  text-align: left;
  background-color:white;
}
th {
  
  border-bottom: 1px solid;
  border-block-color: silver;
  padding: 10px;
  text-align: left;
}
#report-link {
  color: #3b82f6;
  text-decoration: none;
}

.history-skeleton {
  background-color: #f5f5f5;
  height: 5px;
  padding-top: 10%;
}

.progress-spinner {
  position: relative;
  height: 4rem;
  width: 4rem;
  margin: auto;
}

  .progress-spinner:before {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,253);
  }

  .progress-spinner .p-progress-spinner {
    width: 4rem;
    height: 4rem;
  }

  .progress-spinner .p-progress-spinner-circle {
    stroke: #000;
    animation: none;
  }

.error-toast {
  min-width: 25rem;
  max-width: 40rem;
  width: auto !important;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin : 0.5rem 0.5rem 0.5rem 0.5rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  margin: 0 0.5rem 0.5rem 0.5rem;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}




